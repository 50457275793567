import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useCookie } from 'react-use';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { FormattedMessage } from 'react-intl';
import Button from '../Button';
import Grid from '../Grid';
import './style.scss';
import Typography from '../Typography';
import translations from './translations';

const MotionDiv = motion.div;

const CookieConsent = () => {
  const location = useLocation();
  const [cookieConsentAnalytics, setCookieConsentAnalytics] = useCookie('gatsby-gdpr-google-analytics');
  const [cookieConsentTagmanager, setCookieConsentTagmanager] = useCookie('gatsby-gdpr-google-tagmanager');
  const [cookieConsentFacebookPixel, setCookieConsentFacebookPixel] = useCookie('gatsby-gdpr-facebook-pixel');
  const [cookieConsentLinkedIn, setCookieConsentLinkedIn] = useCookie('gatsby-gdpr-linked-in');

  const handleCookieConsent = answer => () => {
    setCookieConsentAnalytics(answer, { expires: 365 });
    setCookieConsentTagmanager(answer, { expires: 365 });
    setCookieConsentFacebookPixel(answer, { expires: 365 });
    setCookieConsentLinkedIn(answer, { expires: 365 });
    if (answer === true) {
      initializeAndTrack(location);
    }
  };

  return (
    <AnimatePresence>
      {cookieConsentAnalytics === null && cookieConsentTagmanager === null && cookieConsentFacebookPixel === null && cookieConsentLinkedIn === null && (
        <MotionDiv
          className="cookie-consent"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          key="cookie-consent"
          initial={{ opacity: 0, y: '32px' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: '32px' }}
          transition={{ duration: 0.2, delay: 0.2, ease: 'easeOut', type: 'tween' }}
        >
          <Typography variant="body-large" weight="bold" color="gray-1000" className="mb-8">
            <FormattedMessage {...translations.title} />
          </Typography>
          <Typography variant="body" color="gray-800" className="mb-24">
            <FormattedMessage {...translations.description} />
            {/* <Link to="/cookie-verklaring/">Lees meer</Link> */}
          </Typography>
          <Grid row spacing={16} justify="end">
            <Grid column className="flex-grow-0">
              <Button size="sm" onClick={handleCookieConsent(true)}>
                <FormattedMessage {...translations.allow} />
              </Button>
            </Grid>
            <Grid column className="flex-grow-0">
              <Button size="sm" onClick={handleCookieConsent(false)} outline color="light">
                <FormattedMessage {...translations.deny} />
              </Button>
            </Grid>
          </Grid>
        </MotionDiv>
      )}
    </AnimatePresence>
  );
};

export default CookieConsent;
