import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './style.scss';

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  body: 'p',
  'body-large': 'p',
  'body-medium': 'p',
  'body-small': 'p',
  'subtitle-1': 'div',
  'subtitle-2': 'div',
};

const Typography = ({
  align,
  variant,
  component,
  color,
  weight,
  disableMargin,
  fontFamily,
  className,
  children,
  ...props
}) => {
  const Component = component || variantMapping[variant] || 'span';

  return (
    <Component
      {...props}
      className={clsx(
        'typography',
        variant,
        {
          [`font-family-${fontFamily}`]: fontFamily,
          [`text-${color}`]: color,
          [`weight-${weight}`]: weight,
          [`text-${align}`]: align,
          'my-0': disableMargin,
        },
        className,
      )}
    >
      {children}
    </Component>
  );
};

export default Typography;

Typography.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right', undefined]),
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'primary-400',
    'primary-300',
    'secondary',
    'danger',
    'warning',
    'success',
    'info',
    'white',
    'text-primary',
    'text-secondary',
    'heading',
    'gray-100',
    'gray-200',
    'gray-300',
    'gray-400',
    'gray-500',
    'gray-600',
    'gray-700',
    'gray-800',
    'gray-900',
    'gray-af-120',
    'gray-af-110',
    'gray-af-100',
    'gray-af-90',
    'gray-af-80',
    'gray-af-70',
    'gray-af-60',
    'gray-af-50',
    'gray-af-40',
    'gray-af-30',
    'gray-af-20',
    'gray-af-10',
    'black',
    undefined,
  ]),
  component: PropTypes.elementType,
  disableMargin: PropTypes.bool,
  fontFamily: PropTypes.oneOf(['base', 'headings', undefined]),
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'display-1',
    'display-2',
    'display-3',
    'display-4',
    'body-small',
    'body',
    'body-medium',
    'body-large',
    'subtitle-1',
    'subtitle-2',
    'button',
    'button-extra-small',
    'button-small',
    'button-medium',
    'button-large',
    'sr-only',
    'overline',
    'inherit',
  ]),
  weight: PropTypes.oneOf([
    'extra-light',
    'light',
    'thin',
    'normal',
    'medium',
    'semi-bold',
    'bold',
    'extra-bold',
    'black',
    undefined,
  ]),
};

Typography.defaultProps = {
  align: undefined,
  className: undefined,
  children: undefined,
  color: undefined,
  component: undefined,
  disableMargin: true,
  fontFamily: undefined,
  variant: 'body',
  weight: undefined,
};
