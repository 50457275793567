import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'site.components.cookieConsent.title',
    defaultMessage: 'Afosto uses cookies',
    description: 'title of the cookie consent banner',
  },
  description: {
    id: 'site.components.cookieConsent.description',
    defaultMessage:
      'We use cookies to make sure our site keeps working, analyse how our site is used and to personalize advertisements.',
    description: 'description of the cookie consent banner',
  },
  allow: {
    id: 'actions.allow',
    defaultMessage: 'Allow',
    description: 'label of te allow action',
  },
  deny: {
    id: 'actions.deny',
    defaultMessage: 'Deny',
    description: 'label of the deny action',
  },
});
