import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Button = React.forwardRef(
  ({ block, color, outline, size, component, href, children, className, ...rest }, ref) => {
    const Component = component || href !== undefined ? 'a' : 'button';

    return (
      <Component
        ref={ref}
        href={href}
        className={clsx(
          'btn',
          {
            [`btn-${outline ? 'outline-' : ''}${color}`]: color,
            [`btn-${size}`]: ['sm', 'lg'].includes(size),
            'btn-block': block,
            'text-gray-900': outline && color === 'light',
            'text-white': outline && color === 'dark',
          },
          className,
        )}
        {...rest}
      >
        {children}
      </Component>
    );
  },
);

Button.propTypes = {
  block: PropTypes.bool,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'dark',
    'light',
    'white',
    'link',
    'afosto-dark',
  ]),
  component: PropTypes.elementType,
  href: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  outline: PropTypes.bool,
};

Button.defaultProps = {
  block: false,
  className: undefined,
  color: 'primary',
  component: undefined,
  href: undefined,
  size: 'md',
  outline: false,
};

export default Button;
